@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html {
    scroll-behavior: smooth;
  }
  
body {
  margin: 0;
  font-family: 'Glacial';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Glacial";
  src: local("Glacial"),
    url("./fonts/GlacialIndifference-Regular.woff") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Glacial Headings";
  src: local("Glacial Headings"),
    url("./fonts/GlacialIndifference-Bold.woff") format("truetype");
  font-weight: bold;
}

.animated {
  visibility: visible;
  opacity: 1;
  transition: opacity 2s linear;
}

.fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.fade-in {
  transition: opacity 0.3s linear;
}

}
